import React from "react";
import {
  Flex,
  Box,
  Text,
  Container,
  Stack,
  HStack,
  Divider,
  Image,
} from "@chakra-ui/react";
import { useBreakpointValue } from "@chakra-ui/react";

const Skills = ({ color }) => {
  const flexDirection = useBreakpointValue({ base: "column", md: "row" });
  return (
    <>
      <Container maxW={"3xl"} id="skills">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                05
              </Text>
              <Text fontWeight={800}>Skills</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack
            spacing={4}
            as={Container}
            maxW={"3xl"}
            textAlign={"center"}
            direction="row"
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "justified",
                flexDirection: flexDirection, // set flexDirection dynamically
                // border: "2px solid red",
              }}
            >
              <Box sx={{ marginTop: "5px" }}>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  INTERESTS
                </Text>
                <Image
                  boxSize="180px"
                  objectFit="cover"
                  src="https://i.imgur.com/lGnIQPR.png"
                  alt="interests-chart"
                />
              </Box>
              <Box sx={{ marginTop: "5px" }}>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  MODERN WEB
                </Text>
                <Image
                  boxSize="180px"
                  // objectFit="cover"
                  src="https://i.imgur.com/nkXeNd8.png"
                  alt="interests-chart"
                />
              </Box>
              <Box sx={{ marginTop: "5px" }}>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  CORE
                </Text>
                <Image
                  boxSize="180px"
                  objectFit="cover"
                  src="https://i.imgur.com/McNXz36.png"
                  alt="interests-chart"
                />
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default Skills;
