import React from "react";
import {
  Flex,
  Box,
  Text,
  Container,
  Stack,
  HStack,
  Divider,
} from "@chakra-ui/react";

const Stats = ({ color }) => {
  return (
    <>
      <Container maxW={"3xl"} id="stats">
        <Stack
          as={Box}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          pb={{ base: 20, md: 36 }}
        >
          <Stack align="center" direction="row" p={4}>
            <HStack mx={4}>
              <Text color={`${color}.400`} fontWeight={800}>
                04
              </Text>
              <Text fontWeight={800}>Stats</Text>
            </HStack>
            <Divider orientation="horizontal" />
          </Stack>
          <Stack
            spacing={4}
            as={Container}
            maxW={"3xl"}
            textAlign={"center"}
            direction="row"
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "justified",
                // border: "2px solid red",
              }}
            >
              <Box>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  112,820
                </Text>
                <Text fontSize="sm">lines of code</Text>
              </Box>
              <Box>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  279,856,008
                </Text>
                <Text fontSize="sm">pixels rendered</Text>
              </Box>
              <Box>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  860
                </Text>
                <Text fontSize="sm">cups of coffee drunk</Text>
              </Box>
              <Box>
                <Text
                  color={`${color}.400`}
                  fontWeight={600}
                  fontSize={["sm", "md", "lg", "xl"]}
                >
                  16
                </Text>
                <Text fontSize="sm">projects completed</Text>
              </Box>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default Stats;

//  <Flex
//    direction="column"
//    alignItems="center"
//    justifyContent="center"
//    //   bg="white"
//    p={5}
//    borderWidth="1px"
//    borderRadius="md"
//    boxShadow="md"
//  >
//    <Box mb={4}>
//      <Text fontSize="xl" fontWeight="bold">
//        Achievements
//      </Text>
//    </Box>
//
//  </Flex>;
