import { Divider, Stack, Text, Container, Box, HStack } from "@chakra-ui/react";
import ProfileArray from "./ProfileArray";
import { useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  VStack,
} from "@chakra-ui/react";
import emailjs from "emailjs-com";

export default function Form({ color }) {
  const [toSend, setToSend] = useState({
    from_name: "",
    to_name: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm("YOUR_SERVICE_ID", "YOUR_TEMPLATE_ID", e.target, "YOUR_USER_ID")
      .then(
        (result) => {
          console.log("Email successfully sent!", result.text);
        },
        (error) => {
          console.error("Error sending email:", error.text);
        }
      );

    // Clear the form fields after sending
    setToSend({
      from_name: "",
      to_name: "",
      message: "",
    });
  };

  const handleChange = (e) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  return (
    <>
      <Container
        maxW={"3xl"}
        id="about"
        sx={{ marginTop: "-50px", marginBottom: "20px" }}
      >
        <VStack
          as="form"
          onSubmit={handleSubmit}
          spacing="4"
          width="100%"
          maxW={"3xl"}
          padding="6"
          boxShadow="md"
          borderRadius="md"
          //   bgColor="white"
        >
          <FormControl id="from_name">
            <FormLabel>Your Name</FormLabel>
            <Input
              type="text"
              name="from_name"
              value={toSend.from_name}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl id="to_name">
            <FormLabel>Email</FormLabel>
            <Input
              type="email"
              name="to_name"
              value={toSend.to_name}
              onChange={handleChange}
              required
            />
          </FormControl>

          <FormControl id="message">
            <FormLabel>Your Message</FormLabel>
            <Textarea
              name="message"
              value={toSend.message}
              onChange={handleChange}
              required
            />
          </FormControl>

          <Button type="submit" colorScheme="blue">
            Send
          </Button>
        </VStack>
      </Container>
    </>
  );
}
